
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital@1&family=Montserrat:wght@100;400;500&family=Poppins:ital,wght@1,300&family=Roboto:wght@300;400&family=Rubik+Glitch&family=Rubik:wght@600&family=Source+Sans+Pro&display=swap');

:root {
   --main-bg-color: #165809;
   --dark-text-color: #333;
   --bg-gray:rgba(224, 221, 221, 0.745);
   --bg-black-color: #e5980f;
   --white-text-color: #FFF;
   --font-family: 'Montserrat', sans-serif;
   --box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;;
   --border: rgba(0, 0, 0, .1);

}

* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   font-family: var(--font-family);

}
li{
     list-style-type: none;
     
}

a{
     text-decoration: none;
}

aside header .navbar{
     padding-left: 40px;
     padding-right: 40px;
     position: fixed;
     top: 0;
     width: 100%;
     background-color: #fff;
     z-index: 100;
     border-bottom: 2px solid var(--main-bg-color);
}
aside header .navbar .brand_logo img{
     width: 100px;
}
aside .sidebar{
width: 250px;
background-color:var(--bg-black-color);
color: #FFF;
padding: 80px 20px 20px 20px;
text-transform: capitalize;
position: fixed;
height: 100vh;
transition: all 0.4s ease-in-out;
transform: translateX(0px);
box-shadow: var(--box-shadow);
top: 0px;
z-index: 10;

}

aside .sidebar.active{
     transform: translateX(-100%);
}
header .box_of_dashbord{
     background-color: var(--main-bg-color);
     color: var(--white-text-color);
     width: 40px;
     height: 40px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 50%;
     border: 1px solid var(--main-bg-color);
     cursor: pointer;
     
}
header  .navul{
     display: flex;
     gap: 20px;
}
aside .sidebar .navbar_pills{
     display: flex;
     padding-left: 10px;
     overflow-y: auto;
}

aside .sidebar .navbar_pills li a{
     margin: 15px 0px;
}

aside .sidebar  .list-item .link.active{
     background-color: var(--white-text-color);
     color: #e5980f;
}
aside .sidebar .menu-side{
     display: flex;
   margin-top: 8px;
     gap: 10px;
     align-items: flex-end;
}
aside .sidebar .menu-side .name{
     color: var(--white-text-color);
     font-size: 15px;
     font-weight: 700;
}
aside .sidebar .menu-side .icons{
     color: var(--white-text-color);
     font-size: 20px;
     font-weight: 700;
}

aside .sidebar .box_userdetail {
background-color: var(--white-text-color);
height: 120px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 15px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 10px;
margin: 10px 0px;
position: relative;

}
aside .sidebar .box_userdetail .avtar{
     
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--main-bg-color);
    font-weight: 600;

}
aside .sidebar .box_userdetail .avtar .span_badge {
     position: absolute;
     bottom: 0;
     margin-bottom: 5px;
 }
aside .sidebar .in_and-out{
     position: absolute;
     right: -25px;
     top: 80px;
     cursor: pointer;
}
.sidebar .in_and-out .icon{
background-color: var(--main-bg-color);
width: 40px;
height: 40px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-size: 20px;
font-family: cursive;
}

.sidebar.active .in_and-out .icon {
transform: rotate(180deg);
}
.sidebar.active .in_and-out{
right: -30px;
}
main .wrapper_content{
     transition: all 0.4s ease-in-out;
     padding-left: 200px;
     padding-top: 100px;
     
}
main .wrapper_content.active{
     padding-left: 0px;
 
} 


/* this is footer authadmin */

.authorize {
     background-color: var(--main-bg-color);
     padding: 10px;
     /* position: absolute;
     top: 100%;
     width: 100%;
     z-index: -2; */
}

.authorize .fotr{
     color: var(--white-text-color);
     font-weight: 700;
     font-size: 20px;
     text-align: center;
     text-transform: capitalize;
     font-family: cursive;
}



/* global css */

.table_heading{
     color: var(--main-bg-color);
     font-weight: 700;
     font-size: 30px;
     text-transform: capitalize;
     font-family: var(--font-family);
     text-align: center;
     
     border-bottom: 2px solid var(--main-bg-color);
     padding-bottom: 10px;
     border-radius: 1px;
}

.table>thead tr th{
     text-transform: capitalize;
     font-weight: 700;
     font-family: var(--font-family);
     color: var(--main-bg-color);
}
.btn.btn_main.btn_form{
     width: 100%;
     text-transform: capitalize;
     font-weight: 700;
     font-size: 20px;
     font-family: var(--font-family);
 }
 .btn.btn_main.btn_form:hover{
     background-color: var(--bg-black-color);
     color: var(--white-text-color);
     border-color: var(--bg-black-color);
 }
.img_table{
     width: 100%;
     height: 100px;
}
#textarea{
     min-height: 200px;
     height: 200px;
}
.form-label{
     text-transform: capitalize;
     color: var(--dark-text-color);
     font-weight: 700;
}
.form-control{
     font-family: var(--font-family);
     text-transform: lowercase;
     color: #0000;
}
.btn-close{
     background-color: var(--main-bg-color);
     color: #fff !important;
     font-family: cursive;
     width: 30px;
     height: 30px;
     border-radius: 50%;
     opacity: 1 !important;
}
.modal-header{
     border-bottom: transparent !important;
}
.dropdown-item{
     text-transform: capitalize;
     font-family: var(--font-family);
     font-weight: 500;
     color: var(--dark-text-color);
}
.dropdown-item:focus{
     background-color:var(--border) !important;
     color: var(--dark-text-color) !important;
}
.page-item.active .page-link{
     background-color: var(--main-bg-color);
     color: var(--white-text-color);
     font-family: cursive;
     font-weight: 500;
     border-color:var(--border) ;
}
.page-item{
     border-color:var(--border) ;
  
}
.page-link{
     color: var(--dark-text-color);
     cursor: pointer;
}
.page-link:hover{
     color: var(--dark-text-color);
}
/* extra css of admin area */

.dashbord_sec{
     text-transform: capitalize;
}

#dashbord .actions .dropdown{
     position: relative;
     transition: all 0.4s;
}

#dashbord .actions .dropdown .menu_drop_down{
     transform: translateY(-20px);
     transition: all 0.4s;
}
#dashbord .actions .dropdown .menu_drop_down.active{
     transform: translateY(0px);
}
#dashbord .actions .dropdown .menu_drop_down.active .orderlist{
     position: absolute;
     top: 0;
     left: 0;
     display: block;
     background-color:var(--white-text-color);
     color: var(--main-bg-color);
     border: 1px solid var(--border);
     padding: 20px;
     border-radius: 15px;
     box-shadow: var(--box-shadow);
}
.menu_drop_down .orderlist{
    
     display: none;
}

#dashbord .actions .dropdown .menu_drop_down .orderlist li a{
     color: var(--main-bg-color);
     font-weight: 500;
     font-family: var(--font-family);
     padding: 10px 0px;
}

.dashbord_sec  .dropdown-menu.show{
box-shadow: var(--box-shadow);
border-color: transparent;
}

.modal-content .main_btn{
     display: flex;
     justify-content: center;
     gap: 20px;
}

/* chaart js style */
.card_wrap .chart{
  box-sizing: border-box;
  height: 150px;
}

.card_wrap .heading_chart{
     font-family: cursive;
     text-transform: capitalize;
     font-weight: 700;
     font-size: 18px;
     color: var(--main-bg-color);
}



/* global css */

.card_wrap{
     background-color: #fff;
border-radius: 20px;
position: relative;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card_body{
     padding: 15px;
     position: relative;
}

.heading_main {
     text-transform: capitalize;
     margin: 20px 0px;
}

.table td .d_flex .btn{
     width: 30px;
     height: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 50%;
}
.table td .d_flex{
     display: flex;
     gap: 10px;
}
.table td .d_flex .btn.edit{
     background-color: var(--main-bg-color);
     color: var(--white-text-color);
}

.table td .d_flex .btn.del{
     background-color: rgb(146, 28, 28);
     color: #fff;
}
.modal_sect .modal-header .btn-close{
      color: #fff !important;
      padding: 5px;
}
.btn_main{
     background-color: var(--bg-black-color);
     color: var(--white-text-color);
     border-radius: 15px;
     text-transform: capitalize;

}
.btn_main:hover{
     background-color: var(--bg-black-color);
     color: var(--white-text-color);
}
.btn_add{
  
     display: flex;
     justify-content: center;
     align-items: center;
}
.card_btn .icon{
     display: flex;
     justify-content: end;
     margin: 20px 0px;
}
.form-control {
     background-color: #e9ecef;
     border-color: #e9ecef;
     color: #000;
     transition: color 0.2s ease;
     height: 50px;
     padding: 0.775rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 15px;

}
.form-control:focus{
     outline: none;
     box-shadow: none;
     background-color: #e6e9ec;
     border-color: #e9ecef;
}

.pagination ul{
     display: flex;
     align-items: center;
}

.pagination ul li a{
     cursor: pointer;
     box-shadow: none;
     outline: none;
     
}

/* responsive styling */

@media (min-width: 576px)
{
     .modal_sect .modal-dialog {
          max-width: 100%;
          margin-right: auto;
          margin-left: auto;
          padding: 20px;
      }
}

@media (max-width:992px) {
     main .wrapper_content {
          transition: all 0.4s ease-in-out;
          padding-left: 10px;
          padding-top: 100px;
      }

      aside .sidebar .in_and-out {
          position: absolute;
          right: -25px;
          top: 90px;
          cursor: pointer;
      }
}
.login .sidebar{
     display: none !important;
}

.login header{
     display: none;
}
.login footer{
     display: none;
}

.login .container{
     padding-top: 100px;
     padding-bottom: 50px;
}

.loader {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
   }

   tbody, td, tfoot, th, thead, tr{
     min-width: 150px;
   }